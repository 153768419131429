import { InlineDrawer } from "@forcepoint/platform-utilityui"
import ConfigurMFA from "./components/ConfigureMFA"

const UserProfileDrawer = ({
  isOpen,
  userDrawerWidth
}: {
  isOpen: boolean
  path: string
  userDrawerWidth: string | number
}) => {
  return (
    <InlineDrawer
      isOpen={isOpen}
      minWidth={userDrawerWidth}
      style={{ position: "relative", borderRadius: "3px" }}
      testId={"user-drawer"}
      hideHeader
    >
      <ConfigurMFA />
    </InlineDrawer>
  )
}

export default UserProfileDrawer
