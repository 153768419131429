import {
  Container,
  NeoComponents,
  CardLoader,
  StyleComponents
} from "@forcepoint/platform-utilityui"
import { useAppDispatch } from "../../../common/state/app.state"
import { useEffect, useState } from "react"
import userProfileService from "../service/userprofile.service"
import QRCode from "qrcode"
import {
  getActionStatus,
  getTotpConfig,
  getTotpStatus
} from "../state/userprofile.selector"
import { ActionState } from "../state/userprofile.state"
import OTPInputBox from "./OTPInputBox"
import { coreStore } from "@forcepoint/platform-coreui"
import { isValidOTPDigit } from "../userprofile.model"
const initPasscodeVal = ["", "", "", "", "", ""]
const TOTPAuthenticator = ({ userId }) => {
  const dispatchApi = useAppDispatch()
  /**
   * enableResetBtn: to enable reset otp button to test for development
   */
  const enableResetBtn = true
  const totpStatus = getTotpStatus(userId)
  const totpConfig = getTotpConfig()
  const actionStatus = getActionStatus()
  const [totpQRUrl, setTotpQRUrl] = useState("")

  const [showTotpSetup, setShowTotpSetup] = useState(false)
  const [totpPasscode, setTotpPasscode] = useState<string[]>([
    ...initPasscodeVal
  ])

  useEffect(() => {
    if (totpConfig.value && totpConfig.value.totpURL) {
      setShowTotpSetup(true)
      generateQRcode(totpConfig.value.totpURL)
    }
  }, [totpConfig])

  useEffect(() => {
    if (
      enableResetBtn &&
      actionStatus[ActionState.RESET_TOTP_CONFIG] &&
      actionStatus[ActionState.RESET_TOTP_CONFIG].actionStatus === "success"
    ) {
      setShowTotpSetup(false)
      dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
      dispatchApi(
        userProfileService.resetActionStatus(ActionState.RESET_TOTP_CONFIG)
      )
    }
    if (
      actionStatus[ActionState.VERIFY_TOTP] &&
      actionStatus[ActionState.VERIFY_TOTP].actionStatus === "success"
    ) {
      if (actionStatus[ActionState.VERIFY_TOTP]?.value?.status) {
        dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
      } else {
        setTotpPasscode([...initPasscodeVal])
      }
      dispatchApi(userProfileService.resetActionStatus(ActionState.VERIFY_TOTP))
    }
  }, [actionStatus])

  const totpSetup = () => {
    dispatchApi(userProfileService.getTOTPConfig())
  }
  const verifyTotp = () => {
    if (totpPasscode.every(isValidOTPDigit)) {
      dispatchApi(
        userProfileService.verifyTOTP({
          passcode: totpPasscode.join("")
        })
      )
    }
    setTotpPasscode(initPasscodeVal)
  }

  useEffect(() => {
    dispatchApi(userProfileService.getTOTPStatus({ id: userId }))
    // return () => {
    //   dispatchApi(userProfileService.resetTotpStatus(userId))
    // }
  }, [])

  const generateQRcode = (str) => {
    QRCode.toDataURL(str, function (err, url) {
      setTotpQRUrl(url)
    })
  }
  const resetTotpConfig = () => {
    if (enableResetBtn) {
      dispatchApi(userProfileService.resetTOTPConfig({ id: userId }))
    }
  }

  return (
    <Container>
      <NeoComponents.NeoExpandableContainer>
        <NeoComponents.NeoCenterFlex
          style={{ width: "100%", height: "100%", flexGrow: 1 }}
        >
          <form className="common-form" noValidate={true}>
            <StyleComponents.Title className="totp-title">
              Verification Code Setup
            </StyleComponents.Title>
            <div style={{ width: "100%" }}>
              {totpStatus.actionStatus === "pending" ? (
                <div>
                  <CardLoader />
                </div>
              ) : null}
              {totpStatus.value && totpStatus.actionStatus === "success" ? (
                <div className="totp-configured-container">
                  <div className="totp-configured">
                    <NeoComponents.NeoIcon
                      fill="green"
                      hoverFill="green"
                      name="success_circle"
                      onClick={function noRefCheck() {}}
                      size={12}
                    />
                    <span className="totp-configured-text">
                      Verification Code Configured
                    </span>
                  </div>
                  {enableResetBtn ? (
                    <NeoComponents.NeoPrimaryButton
                      onClick={resetTotpConfig}
                      isLoading={
                        actionStatus[ActionState.RESET_TOTP_CONFIG] &&
                        actionStatus[ActionState.RESET_TOTP_CONFIG]
                          .actionStatus === "pending"
                      }
                    >
                      Reset
                    </NeoComponents.NeoPrimaryButton>
                  ) : null}
                </div>
              ) : null}
              {showTotpSetup &&
              !totpStatus.value &&
              totpStatus.actionStatus === "success" &&
              totpConfig.value.secretKey &&
              totpConfig.value.totpURL &&
              totpConfig.actionStatus === "success" ? (
                <>
                  <div className="qr-text-center">
                    <p className="scan-text">
                      Scan the QR code using the Google Authenticator App on
                      your Android or iOS device.
                    </p>
                    <img
                      height={"170px"}
                      width={"auto"}
                      src={totpQRUrl}
                      alt="QR Code"
                    />

                    <p className="scan-text">
                      If you cannot scan the barcode, enter the following key
                    </p>
                    <div className="secret-key-code">
                      <div className="disabled-div">
                        {totpConfig.value.secretKey &&
                          Array(6)
                            .fill("")
                            .map((val, index) =>
                              totpConfig.value.secretKey.slice(
                                index * 4,
                                index * 4 + 4
                              )
                            )
                            .join(" ")}
                      </div>
                    </div>
                  </div>
                  {totpConfig.value.secretKey ? (
                    <div className="inputs otp-container">
                      <OTPInputBox
                        passcode={totpPasscode}
                        setPassCode={setTotpPasscode}
                      />
                      <label htmlFor="otp-box-0">Verification code</label>
                    </div>
                  ) : null}
                </>
              ) : null}
              {!showTotpSetup &&
              !totpStatus.value &&
              totpStatus.actionStatus === "success" ? (
                <div className="start-totp-btn">
                  <NeoComponents.NeoPrimaryButton
                    isLoading={totpConfig.actionStatus === "pending"}
                    onClick={totpSetup}
                  >
                    Start Verification Code Setup
                  </NeoComponents.NeoPrimaryButton>
                </div>
              ) : null}
            </div>
            {showTotpSetup &&
            !totpStatus.value &&
            totpStatus.actionStatus === "success" ? (
              <div className="verify-totp-btn">
                <NeoComponents.NeoPrimaryButton
                  isDisabled={!totpPasscode.every(isValidOTPDigit)}
                  isLoading={
                    totpConfig.actionStatus === "pending" ||
                    (actionStatus[ActionState.VERIFY_TOTP] &&
                      actionStatus[ActionState.VERIFY_TOTP].actionStatus ===
                        "pending")
                  }
                  onClick={verifyTotp}
                >
                  Verify
                </NeoComponents.NeoPrimaryButton>
              </div>
            ) : null}
          </form>
        </NeoComponents.NeoCenterFlex>
      </NeoComponents.NeoExpandableContainer>
    </Container>
  )
}
export default TOTPAuthenticator
