export const blockInvalidChar = (e) => {
  const charCode = e.which ? e.which : e.keyCode
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    (charCode < 96 || charCode > 105) &&
    charCode !== 39 &&
    charCode !== 37
  ) {
    e.preventDefault()
  }
}
