import { FC, useRef, SetStateAction, Dispatch } from "react"
import { isValidOTPDigit } from "../userprofile.model"

const OTPInputBox: FC<{
  passcode: string[]
  setPassCode: Dispatch<SetStateAction<string[]>>
}> = ({ passcode, setPassCode }) => {
  // Create an array of refs
  const otpFields = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]

  const onOTPKeyDown = (event, index) => {
    const { value } = event.target
    if (value.length === 1) {
      if (index < otpFields.length - 1) {
        otpFields[index + 1].current.focus()
        otpFields[index + 1].current.select()
      }
    } else if (value.length === 0) {
      if (index > 0) {
        otpFields[index - 1].current.focus()
        otpFields[index - 1].current.select()
      }
    }
  }

  return (
    <>
      {otpFields.map((ref, i) => (
        <input
          size={1}
          autoComplete="off"
          id={`otp-box-${i}`}
          key={`otp-box-${i}`}
          ref={ref}
          className={`otp-input-box ${!!passcode[i] && !isValidOTPDigit(passcode[i]) ? "invalid" : ""}`}
          type="text"
          value={passcode[i] || ""}
          onChange={(event) => {
            const otp = [...passcode]
            otp[i] = event.target.value
            setPassCode(otp)
            onOTPKeyDown(event, i)
          }}
          maxLength={1}
        />
      ))}
    </>
  )
}

export default OTPInputBox
