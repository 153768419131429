import { AnyAction, createSlice } from "@reduxjs/toolkit"
import { AsyncReducers } from "../app.model"

function isRejectedAction(action: AnyAction) {
  return action.type.endsWith("rejected")
}

function isFulfilledAction(action: AnyAction) {
  return action.type.endsWith("fulfilled")
}

function isPendingAction(action: AnyAction) {
  return action.type.endsWith("pending")
}

function getHeaders(headersData) {
  const headers = ["x-total-count", "content-disposition"]
  return headers.reduce((obj, val) => {
    obj[val] = headersData[val]
    return obj
  }, {})
}

function parseCustomPromiseResponse(arr) {
  const resList = []
  if (arr && arr.length > 0) {
    for (const index in arr) {
      if (arr[index].status === "fulfilled") {
        resList[index] = arr[index].value.data
      } else if (arr[index].status === "rejected") {
        resList[index] = null
      }
    }
  }
  return resList
}

const asyncAPIReducers = (reducersObj: AsyncReducers) => (builder) => {
  builder.addDefaultCase((state, action: AnyAction) => {
    if (action.payload && action.payload.headers) {
      action.meta.headers = getHeaders(action.payload.headers)
      action.payload = action.payload.data
    }
    if (action.payload && action.payload.custom) {
      action.payload = parseCustomPromiseResponse(action.payload.promise)
    }

    const type = action.type.split("/")[0]
    if (isFulfilledAction(action)) {
      if (reducersObj[type] && reducersObj[type].success) {
        reducersObj[type].success(state, action)
      }
    } else if (isPendingAction(action)) {
      if (reducersObj[type] && reducersObj[type].pending) {
        reducersObj[type].pending(state, action)
      }
    } else if (isRejectedAction(action)) {
      if (reducersObj[type] && reducersObj[type].rejected) {
        reducersObj[type].rejected(state, action)
      }
    } else {
      if (reducersObj[type] && reducersObj[type].success) {
        reducersObj[type].success(state, action)
      }
      if (reducersObj[type] && reducersObj[type].pending) {
        reducersObj[type].pending(state, action)
      }
      if (reducersObj[type] && reducersObj[type].rejected) {
        reducersObj[type].rejected(state, action)
      }
    }
  })
}

// export const addReducers = function (key: string, obj: ReducerActions) {
//     reducersObj[key] = obj;
// }

export const createCustomSlice = ({
  name,
  initialState,
  reducers,
  asyncReducers
}) => {
  return createSlice({
    name,
    initialState,
    reducers: reducers,
    extraReducers: asyncAPIReducers(asyncReducers)
  })
}
