import { createCustomSlice } from "../../../common/state/app.state.commons"
import { ActionState, intialUserProfileState } from "./userprofile.state"
import { AsyncReducers } from "../../../common/app.model"
import { UserProfileState } from "../userprofile.model"

const initialState: UserProfileState = intialUserProfileState

const asyncReducers: AsyncReducers = {
  [ActionState.GET_USER_BY_ID]: {
    pending: (state) => {
      state.userById.actionStatus = "pending"
      state.userById.showLoader = true
    },
    success: (state, action) => {
      state.userById.value = action.payload[0]
      state.userById.actionStatus = "success"
      state.userById.showLoader = false
    },
    rejected: (state) => {
      state.userById.actionStatus = "rejected"
      state.userById.showLoader = false
    }
  },
  [ActionState.EDIT_USER]: {
    pending: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "pending",
        showLoader: true
      }
    },
    success: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: action.payload,
        actionStatus: "success",
        showLoader: false
      }
    },
    rejected: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "failed",
        showLoader: false
      }
    }
  },
  [ActionState.GET_TOTP_STATUS]: {
    pending: (state, action) => {
      if (action.meta.arg.id) {
        state.totpStatus[action.meta.arg.id] = {}
        state.totpStatus[action.meta.arg.id].actionStatus = "pending"
        state.totpStatus[action.meta.arg.id].showLoader = true
      }
    },
    success: (state, action) => {
      if (action.meta.arg.id) {
        state.totpStatus[action.meta.arg.id].value = action.payload.status
        state.totpStatus[action.meta.arg.id].actionStatus = "success"
        state.totpStatus[action.meta.arg.id].showLoader = false
      }
    },
    rejected: (state, action) => {
      if (action.meta.arg.id) {
        state.totpStatus[action.meta.arg.id].actionStatus = "rejected"
        state.totpStatus[action.meta.arg.id].showLoader = false
        state.totpStatus[action.meta.arg.id].value = false
      }
    }
  },
  [ActionState.GET_DUO_STATUS]: {
    pending: (state, action) => {
      if (action.meta.arg.id) {
        state.duoStatus[action.meta.arg.id] = {}
        state.duoStatus[action.meta.arg.id].actionStatus = "pending"
        state.duoStatus[action.meta.arg.id].showLoader = true
      }
    },
    success: (state, action) => {
      if (action.meta.arg.id) {
        state.duoStatus[action.meta.arg.id].value = action.payload.status
        state.duoStatus[action.meta.arg.id].actionStatus = "success"
        state.duoStatus[action.meta.arg.id].showLoader = false
      }
    },
    rejected: (state, action) => {
      if (action.meta.arg.id) {
        state.duoStatus[action.meta.arg.id].actionStatus = "rejected"
        state.duoStatus[action.meta.arg.id].showLoader = false
        state.duoStatus[action.meta.arg.id].value = false
      }
    }
  },
  [ActionState.GET_DUO_CONFIGURATION]: {
    pending: (state) => {
      state.duoConfiguration.actionStatus = "pending"
      state.duoConfiguration.showLoader = true
      state.duoConfiguration.value = false
    },
    success: (state, action) => {
      if (
        action.payload &&
        action.payload["duo.AdminIntegrationKey"] &&
        action.payload["duo.AdminSecretKey"] &&
        action.payload["duo.AuthIntegrationKey"] &&
        action.payload["duo.AuthSecretKey"] &&
        action.payload["duo.Host"]
      ) {
        state.duoConfiguration.value = action.payload
      }
      state.duoConfiguration.actionStatus = "success"
      state.duoConfiguration.showLoader = false
    },
    rejected: (state) => {
      state.duoConfiguration.actionStatus = "rejected"
      state.duoConfiguration.showLoader = false
      state.duoConfiguration.value = false
    }
  },
  [ActionState.GET_DUO_USER_CONFIG]: {
    pending: (state) => {
      state.duoUserConfig.actionStatus = "pending"
      state.duoUserConfig.showLoader = true
      state.duoUserConfig.value = {}
    },
    success: (state, action) => {
      state.duoUserConfig.value = action.payload
      state.duoUserConfig.actionStatus = "success"
      state.duoUserConfig.showLoader = false
    },
    rejected: (state) => {
      state.duoUserConfig.actionStatus = "rejected"
      state.duoUserConfig.showLoader = false
      state.duoUserConfig.value = {}
    }
  },
  [ActionState.GET_TOTP_CONFIG]: {
    pending: (state) => {
      state.totpConfig.actionStatus = "pending"
      state.totpConfig.showLoader = true
      state.totpConfig.value = {}
    },
    success: (state, action) => {
      state.totpConfig.value = action.payload
      state.totpConfig.actionStatus = "success"
      state.totpConfig.showLoader = false
    },
    rejected: (state) => {
      state.totpConfig.actionStatus = "rejected"
      state.totpConfig.showLoader = false
      state.totpConfig.value = {}
    }
  },
  [ActionState.RESET_DUO_USER_CONFIG]: {
    pending: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "pending",
        showLoader: true
      }
    },
    success: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: action.payload,
        actionStatus: "success",
        showLoader: false
      }
    },
    rejected: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "failed",
        showLoader: false
      }
    }
  },
  [ActionState.VERIFY_DUO_USER]: {
    pending: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "pending",
        showLoader: true
      }
    },
    success: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: action.payload,
        actionStatus: "success",
        showLoader: false
      }
    },
    rejected: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "failed",
        showLoader: false
      }
    }
  },
  [ActionState.RESET_TOTP_CONFIG]: {
    pending: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "pending",
        showLoader: true
      }
    },
    success: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: action.payload,
        actionStatus: "success",
        showLoader: false
      }
    },
    rejected: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "failed",
        showLoader: false
      }
    }
  },
  [ActionState.VERIFY_TOTP]: {
    pending: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "pending",
        showLoader: true
      }
    },
    success: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: action.payload,
        actionStatus: "success",
        showLoader: false
      }
    },
    rejected: (state, action) => {
      state.actionStatus[action.type.split("/")[0]] = {
        value: null,
        actionStatus: "failed",
        showLoader: false
      }
    }
  },
  [ActionState.RESET_MFA_PAGE_STATE]: {
    success: (state) => {
      state.duoConfiguration = { value: null, actionStatus: null }
      state.duoUserConfig = { value: null, actionStatus: null }
      state.totpConfig = { value: {}, actionStatus: null }
    }
  },
  [ActionState.RESET_ACTION_STATUS]: {
    success: (state, action) => {
      delete state.actionStatus[action.payload]
    }
  },
  [ActionState.RESET_TOTP_STATUS]: {
    success: (state, action) => {
      delete state.totpStatus[action.payload]
    }
  },
  [ActionState.RESET_DUO_STATUS]: {
    success: (state, action) => {
      delete state.duoStatus[action.payload]
    }
  },
  [ActionState.RESET_USER_STATE]: {
    success: (state) => {
      state.userById = { value: null, actionStatus: null, showLoader: false }
    }
  }
}

/**
 * @name
 * @InitialState
 * @Reducers
 * @ExtraReducer
 */
const slice = createCustomSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  asyncReducers: asyncReducers
})

export const {} = slice.actions

export default slice.reducer
