export const APP_CONSTANTS = {}

export const CODE_LIST = [
  {
    displayName: "Afghanistan +93",
    value: "+93",
    code: "AF",
    text: "AF +93"
  },
  {
    displayName: "Aland Islands +358",
    value: "+358",
    code: "AX",
    text: "AX +358"
  },
  {
    displayName: "Albania +355",
    value: "+355",
    code: "AL",
    text: "AL +355"
  },
  {
    displayName: "Algeria +213",
    value: "+213",
    code: "DZ",
    text: "DZ +213"
  },
  {
    displayName: "AmericanSamoa +1684",
    value: "+1684",
    code: "AS",
    text: "AS +1684"
  },
  {
    displayName: "Andorra +376",
    value: "+376",
    code: "AD",
    text: "AD +376"
  },
  {
    displayName: "Angola +244",
    value: "+244",
    code: "AO",
    text: "AO +244"
  },
  {
    displayName: "Anguilla +1264",
    value: "+1264",
    code: "AI",
    text: "AI +1264"
  },
  {
    displayName: "Antarctica +672",
    value: "+672",
    code: "AQ",
    text: "AQ +672"
  },
  {
    displayName: "Antigua and Barbuda +1268",
    value: "+1268",
    code: "AG",
    text: "AG +1268"
  },
  {
    displayName: "Argentina +54",
    value: "+54",
    code: "AR",
    text: "AR +54"
  },
  {
    displayName: "Armenia +374",
    value: "+374",
    code: "AM",
    text: "AM +374"
  },
  {
    displayName: "Aruba +297",
    value: "+297",
    code: "AW",
    text: "AW +297"
  },
  {
    displayName: "Australia +61",
    value: "+61",
    code: "AU",
    text: "AU +61"
  },
  {
    displayName: "Austria +43",
    value: "+43",
    code: "AT",
    text: "AT +43"
  },
  {
    displayName: "Azerbaijan +994",
    value: "+994",
    code: "AZ",
    text: "AZ +994"
  },
  {
    displayName: "Bahamas +1242",
    value: "+1242",
    code: "BS",
    text: "BS +1242"
  },
  {
    displayName: "Bahrain +973",
    value: "+973",
    code: "BH",
    text: "BH +973"
  },
  {
    displayName: "Bangladesh +880",
    value: "+880",
    code: "BD",
    text: "BD +880"
  },
  {
    displayName: "Barbados +1246",
    value: "+1246",
    code: "BB",
    text: "BB +1246"
  },
  {
    displayName: "Belarus +375",
    value: "+375",
    code: "BY",
    text: "BY +375"
  },
  {
    displayName: "Belgium +32",
    value: "+32",
    code: "BE",
    text: "BE +32"
  },
  {
    displayName: "Belize +501",
    value: "+501",
    code: "BZ",
    text: "BZ +501"
  },
  {
    displayName: "Benin +229",
    value: "+229",
    code: "BJ",
    text: "BJ +229"
  },
  {
    displayName: "Bermuda +1441",
    value: "+1441",
    code: "BM",
    text: "BM +1441"
  },
  {
    displayName: "Bhutan +975",
    value: "+975",
    code: "BT",
    text: "BT +975"
  },
  {
    displayName: "Bolivia, Plurinational State of +591",
    value: "+591",
    code: "BO",
    text: "BO +591"
  },
  {
    displayName: "Bosnia and Herzegovina +387",
    value: "+387",
    code: "BA",
    text: "BA +387"
  },
  {
    displayName: "Botswana +267",
    value: "+267",
    code: "BW",
    text: "BW +267"
  },
  {
    displayName: "Brazil +55",
    value: "+55",
    code: "BR",
    text: "BR +55"
  },
  {
    displayName: "British Indian Ocean Territory +246",
    value: "+246",
    code: "IO",
    text: "IO +246"
  },
  {
    displayName: "Brunei Darussalam +673",
    value: "+673",
    code: "BN",
    text: "BN +673"
  },
  {
    displayName: "Bulgaria +359",
    value: "+359",
    code: "BG",
    text: "BG +359"
  },
  {
    displayName: "Burkina Faso +226",
    value: "+226",
    code: "BF",
    text: "BF +226"
  },
  {
    displayName: "Burundi +257",
    value: "+257",
    code: "BI",
    text: "BI +257"
  },
  {
    displayName: "Cambodia +855",
    value: "+855",
    code: "KH",
    text: "KH +855"
  },
  {
    displayName: "Cameroon +237",
    value: "+237",
    code: "CM",
    text: "CM +237"
  },
  {
    displayName: "Canada +1",
    value: "+1",
    code: "CA",
    text: "CA +1"
  },
  {
    displayName: "Cape Verde +238",
    value: "+238",
    code: "CV",
    text: "CV +238"
  },
  {
    displayName: "Cayman Islands + 345",
    value: "+ 345",
    code: "KY",
    text: "KY + 345"
  },
  {
    displayName: "Central African Republic +236",
    value: "+236",
    code: "CF",
    text: "CF +236"
  },
  {
    displayName: "Chad +235",
    value: "+235",
    code: "TD",
    text: "TD +235"
  },
  {
    displayName: "Chile +56",
    value: "+56",
    code: "CL",
    text: "CL +56"
  },
  {
    displayName: "China +86",
    value: "+86",
    code: "CN",
    text: "CN +86"
  },
  {
    displayName: "Christmas Island +61",
    value: "+61",
    code: "CX",
    text: "CX +61"
  },
  {
    displayName: "Cocos (Keeling) Islands +61",
    value: "+61",
    code: "CC",
    text: "CC +61"
  },
  {
    displayName: "Colombia +57",
    value: "+57",
    code: "CO",
    text: "CO +57"
  },
  {
    displayName: "Comoros +269",
    value: "+269",
    code: "KM",
    text: "KM +269"
  },
  {
    displayName: "Congo +242",
    value: "+242",
    code: "CG",
    text: "CG +242"
  },
  {
    displayName: "Congo, The Democratic Republic of the Congo +243",
    value: "+243",
    code: "CD",
    text: "CD +243"
  },
  {
    displayName: "Cook Islands +682",
    value: "+682",
    code: "CK",
    text: "CK +682"
  },
  {
    displayName: "Costa Rica +506",
    value: "+506",
    code: "CR",
    text: "CR +506"
  },
  {
    displayName: "Cote d'Ivoire +225",
    value: "+225",
    code: "CI",
    text: "CI +225"
  },
  {
    displayName: "Croatia +385",
    value: "+385",
    code: "HR",
    text: "HR +385"
  },
  {
    displayName: "Cuba +53",
    value: "+53",
    code: "CU",
    text: "CU +53"
  },
  {
    displayName: "Cyprus +357",
    value: "+357",
    code: "CY",
    text: "CY +357"
  },
  {
    displayName: "Czech Republic +420",
    value: "+420",
    code: "CZ",
    text: "CZ +420"
  },
  {
    displayName: "Denmark +45",
    value: "+45",
    code: "DK",
    text: "DK +45"
  },
  {
    displayName: "Djibouti +253",
    value: "+253",
    code: "DJ",
    text: "DJ +253"
  },
  {
    displayName: "Dominica +1767",
    value: "+1767",
    code: "DM",
    text: "DM +1767"
  },
  {
    displayName: "Dominican Republic +1849",
    value: "+1849",
    code: "DO",
    text: "DO +1849"
  },
  {
    displayName: "Ecuador +593",
    value: "+593",
    code: "EC",
    text: "EC +593"
  },
  {
    displayName: "Egypt +20",
    value: "+20",
    code: "EG",
    text: "EG +20"
  },
  {
    displayName: "El Salvador +503",
    value: "+503",
    code: "SV",
    text: "SV +503"
  },
  {
    displayName: "Equatorial Guinea +240",
    value: "+240",
    code: "GQ",
    text: "GQ +240"
  },
  {
    displayName: "Eritrea +291",
    value: "+291",
    code: "ER",
    text: "ER +291"
  },
  {
    displayName: "Estonia +372",
    value: "+372",
    code: "EE",
    text: "EE +372"
  },
  {
    displayName: "Ethiopia +251",
    value: "+251",
    code: "ET",
    text: "ET +251"
  },
  {
    displayName: "Falkland Islands (Malvinas) +500",
    value: "+500",
    code: "FK",
    text: "FK +500"
  },
  {
    displayName: "Faroe Islands +298",
    value: "+298",
    code: "FO",
    text: "FO +298"
  },
  {
    displayName: "Fiji +679",
    value: "+679",
    code: "FJ",
    text: "FJ +679"
  },
  {
    displayName: "Finland +358",
    value: "+358",
    code: "FI",
    text: "FI +358"
  },
  {
    displayName: "France +33",
    value: "+33",
    code: "FR",
    text: "FR +33"
  },
  {
    displayName: "French Guiana +594",
    value: "+594",
    code: "GF",
    text: "GF +594"
  },
  {
    displayName: "French Polynesia +689",
    value: "+689",
    code: "PF",
    text: "PF +689"
  },
  {
    displayName: "Gabon +241",
    value: "+241",
    code: "GA",
    text: "GA +241"
  },
  {
    displayName: "Gambia +220",
    value: "+220",
    code: "GM",
    text: "GM +220"
  },
  {
    displayName: "Georgia +995",
    value: "+995",
    code: "GE",
    text: "GE +995"
  },
  {
    displayName: "Germany +49",
    value: "+49",
    code: "DE",
    text: "DE +49"
  },
  {
    displayName: "Ghana +233",
    value: "+233",
    code: "GH",
    text: "GH +233"
  },
  {
    displayName: "Gibraltar +350",
    value: "+350",
    code: "GI",
    text: "GI +350"
  },
  {
    displayName: "Greece +30",
    value: "+30",
    code: "GR",
    text: "GR +30"
  },
  {
    displayName: "Greenland +299",
    value: "+299",
    code: "GL",
    text: "GL +299"
  },
  {
    displayName: "Grenada +1473",
    value: "+1473",
    code: "GD",
    text: "GD +1473"
  },
  {
    displayName: "Guadeloupe +590",
    value: "+590",
    code: "GP",
    text: "GP +590"
  },
  {
    displayName: "Guam +1671",
    value: "+1671",
    code: "GU",
    text: "GU +1671"
  },
  {
    displayName: "Guatemala +502",
    value: "+502",
    code: "GT",
    text: "GT +502"
  },
  {
    displayName: "Guernsey +44",
    value: "+44",
    code: "GG",
    text: "GG +44"
  },
  {
    displayName: "Guinea +224",
    value: "+224",
    code: "GN",
    text: "GN +224"
  },
  {
    displayName: "Guinea-Bissau +245",
    value: "+245",
    code: "GW",
    text: "GW +245"
  },
  {
    displayName: "Guyana +595",
    value: "+595",
    code: "GY",
    text: "GY +595"
  },
  {
    displayName: "Haiti +509",
    value: "+509",
    code: "HT",
    text: "HT +509"
  },
  {
    displayName: "Holy See (Vatican City State) +379",
    value: "+379",
    code: "VA",
    text: "VA +379"
  },
  {
    displayName: "Honduras +504",
    value: "+504",
    code: "HN",
    text: "HN +504"
  },
  {
    displayName: "Hong Kong +852",
    value: "+852",
    code: "HK",
    text: "HK +852"
  },
  {
    displayName: "Hungary +36",
    value: "+36",
    code: "HU",
    text: "HU +36"
  },
  {
    displayName: "Iceland +354",
    value: "+354",
    code: "IS",
    text: "IS +354"
  },
  {
    displayName: "India +91",
    value: "+91",
    code: "IN",
    text: "IN +91"
  },
  {
    displayName: "Indonesia +62",
    value: "+62",
    code: "ID",
    text: "ID +62"
  },
  {
    displayName: "Iran, Islamic Republic of Persian Gulf +98",
    value: "+98",
    code: "IR",
    text: "IR +98"
  },
  {
    displayName: "Iraq +964",
    value: "+964",
    code: "IQ",
    text: "IQ +964"
  },
  {
    displayName: "Ireland +353",
    value: "+353",
    code: "IE",
    text: "IE +353"
  },
  {
    displayName: "Isle of Man +44",
    value: "+44",
    code: "IM",
    text: "IM +44"
  },
  {
    displayName: "Israel +972",
    value: "+972",
    code: "IL",
    text: "IL +972"
  },
  {
    displayName: "Italy +39",
    value: "+39",
    code: "IT",
    text: "IT +39"
  },
  {
    displayName: "Jamaica +1876",
    value: "+1876",
    code: "JM",
    text: "JM +1876"
  },
  {
    displayName: "Japan +81",
    value: "+81",
    code: "JP",
    text: "JP +81"
  },
  {
    displayName: "Jersey +44",
    value: "+44",
    code: "JE",
    text: "JE +44"
  },
  {
    displayName: "Jordan +962",
    value: "+962",
    code: "JO",
    text: "JO +962"
  },
  {
    displayName: "Kazakhstan +77",
    value: "+77",
    code: "KZ",
    text: "KZ +77"
  },
  {
    displayName: "Kenya +254",
    value: "+254",
    code: "KE",
    text: "KE +254"
  },
  {
    displayName: "Kiribati +686",
    value: "+686",
    code: "KI",
    text: "KI +686"
  },
  {
    displayName: "Korea, Democratic People's Republic of Korea +850",
    value: "+850",
    code: "KP",
    text: "KP +850"
  },
  {
    displayName: "Korea, Republic of South Korea +82",
    value: "+82",
    code: "KR",
    text: "KR +82"
  },
  {
    displayName: "Kuwait +965",
    value: "+965",
    code: "KW",
    text: "KW +965"
  },
  {
    displayName: "Kyrgyzstan +996",
    value: "+996",
    code: "KG",
    text: "KG +996"
  },
  {
    displayName: "Laos +856",
    value: "+856",
    code: "LA",
    text: "LA +856"
  },
  {
    displayName: "Latvia +371",
    value: "+371",
    code: "LV",
    text: "LV +371"
  },
  {
    displayName: "Lebanon +961",
    value: "+961",
    code: "LB",
    text: "LB +961"
  },
  {
    displayName: "Lesotho +266",
    value: "+266",
    code: "LS",
    text: "LS +266"
  },
  {
    displayName: "Liberia +231",
    value: "+231",
    code: "LR",
    text: "LR +231"
  },
  {
    displayName: "Libyan Arab Jamahiriya +218",
    value: "+218",
    code: "LY",
    text: "LY +218"
  },
  {
    displayName: "Liechtenstein +423",
    value: "+423",
    code: "LI",
    text: "LI +423"
  },
  {
    displayName: "Lithuania +370",
    value: "+370",
    code: "LT",
    text: "LT +370"
  },
  {
    displayName: "Luxembourg +352",
    value: "+352",
    code: "LU",
    text: "LU +352"
  },
  {
    displayName: "Macao +853",
    value: "+853",
    code: "MO",
    text: "MO +853"
  },
  {
    displayName: "Macedonia +389",
    value: "+389",
    code: "MK",
    text: "MK +389"
  },
  {
    displayName: "Madagascar +261",
    value: "+261",
    code: "MG",
    text: "MG +261"
  },
  {
    displayName: "Malawi +265",
    value: "+265",
    code: "MW",
    text: "MW +265"
  },
  {
    displayName: "Malaysia +60",
    value: "+60",
    code: "MY",
    text: "MY +60"
  },
  {
    displayName: "Maldives +960",
    value: "+960",
    code: "MV",
    text: "MV +960"
  },
  {
    displayName: "Mali +223",
    value: "+223",
    code: "ML",
    text: "ML +223"
  },
  {
    displayName: "Malta +356",
    value: "+356",
    code: "MT",
    text: "MT +356"
  },
  {
    displayName: "Marshall Islands +692",
    value: "+692",
    code: "MH",
    text: "MH +692"
  },
  {
    displayName: "Martinique +596",
    value: "+596",
    code: "MQ",
    text: "MQ +596"
  },
  {
    displayName: "Mauritania +222",
    value: "+222",
    code: "MR",
    text: "MR +222"
  },
  {
    displayName: "Mauritius +230",
    value: "+230",
    code: "MU",
    text: "MU +230"
  },
  {
    displayName: "Mayotte +262",
    value: "+262",
    code: "YT",
    text: "YT +262"
  },
  {
    displayName: "Mexico +52",
    value: "+52",
    code: "MX",
    text: "MX +52"
  },
  {
    displayName: "Micronesia, Federated States of Micronesia +691",
    value: "+691",
    code: "FM",
    text: "FM +691"
  },
  {
    displayName: "Moldova +373",
    value: "+373",
    code: "MD",
    text: "MD +373"
  },
  {
    displayName: "Monaco +377",
    value: "+377",
    code: "MC",
    text: "MC +377"
  },
  {
    displayName: "Mongolia +976",
    value: "+976",
    code: "MN",
    text: "MN +976"
  },
  {
    displayName: "Montenegro +382",
    value: "+382",
    code: "ME",
    text: "ME +382"
  },
  {
    displayName: "Montserrat +1664",
    value: "+1664",
    code: "MS",
    text: "MS +1664"
  },
  {
    displayName: "Morocco +212",
    value: "+212",
    code: "MA",
    text: "MA +212"
  },
  {
    displayName: "Mozambique +258",
    value: "+258",
    code: "MZ",
    text: "MZ +258"
  },
  {
    displayName: "Myanmar +95",
    value: "+95",
    code: "MM",
    text: "MM +95"
  },
  {
    displayName: "Namibia +264",
    value: "+264",
    code: "NA",
    text: "NA +264"
  },
  {
    displayName: "Nauru +674",
    value: "+674",
    code: "NR",
    text: "NR +674"
  },
  {
    displayName: "Nepal +977",
    value: "+977",
    code: "NP",
    text: "NP +977"
  },
  {
    displayName: "Netherlands +31",
    value: "+31",
    code: "NL",
    text: "NL +31"
  },
  {
    displayName: "Netherlands Antilles +599",
    value: "+599",
    code: "AN",
    text: "AN +599"
  },
  {
    displayName: "New Caledonia +687",
    value: "+687",
    code: "NC",
    text: "NC +687"
  },
  {
    displayName: "New Zealand +64",
    value: "+64",
    code: "NZ",
    text: "NZ +64"
  },
  {
    displayName: "Nicaragua +505",
    value: "+505",
    code: "NI",
    text: "NI +505"
  },
  {
    displayName: "Niger +227",
    value: "+227",
    code: "NE",
    text: "NE +227"
  },
  {
    displayName: "Nigeria +234",
    value: "+234",
    code: "NG",
    text: "NG +234"
  },
  {
    displayName: "Niue +683",
    value: "+683",
    code: "NU",
    text: "NU +683"
  },
  {
    displayName: "Norfolk Island +672",
    value: "+672",
    code: "NF",
    text: "NF +672"
  },
  {
    displayName: "Northern Mariana Islands +1670",
    value: "+1670",
    code: "MP",
    text: "MP +1670"
  },
  {
    displayName: "Norway +47",
    value: "+47",
    code: "NO",
    text: "NO +47"
  },
  {
    displayName: "Oman +968",
    value: "+968",
    code: "OM",
    text: "OM +968"
  },
  {
    displayName: "Pakistan +92",
    value: "+92",
    code: "PK",
    text: "PK +92"
  },
  {
    displayName: "Palau +680",
    value: "+680",
    code: "PW",
    text: "PW +680"
  },
  {
    displayName: "Palestinian Territory, Occupied +970",
    value: "+970",
    code: "PS",
    text: "PS +970"
  },
  {
    displayName: "Panama +507",
    value: "+507",
    code: "PA",
    text: "PA +507"
  },
  {
    displayName: "Papua New Guinea +675",
    value: "+675",
    code: "PG",
    text: "PG +675"
  },
  {
    displayName: "Paraguay +595",
    value: "+595",
    code: "PY",
    text: "PY +595"
  },
  {
    displayName: "Peru +51",
    value: "+51",
    code: "PE",
    text: "PE +51"
  },
  {
    displayName: "Philippines +63",
    value: "+63",
    code: "PH",
    text: "PH +63"
  },
  {
    displayName: "Pitcairn +872",
    value: "+872",
    code: "PN",
    text: "PN +872"
  },
  {
    displayName: "Poland +48",
    value: "+48",
    code: "PL",
    text: "PL +48"
  },
  {
    displayName: "Portugal +351",
    value: "+351",
    code: "PT",
    text: "PT +351"
  },
  {
    displayName: "Puerto Rico +1939",
    value: "+1939",
    code: "PR",
    text: "PR +1939"
  },
  {
    displayName: "Qatar +974",
    value: "+974",
    code: "QA",
    text: "QA +974"
  },
  {
    displayName: "Romania +40",
    value: "+40",
    code: "RO",
    text: "RO +40"
  },
  {
    displayName: "Russia +7",
    value: "+7",
    code: "RU",
    text: "RU +7"
  },
  {
    displayName: "Rwanda +250",
    value: "+250",
    code: "RW",
    text: "RW +250"
  },
  {
    displayName: "Reunion +262",
    value: "+262",
    code: "RE",
    text: "RE +262"
  },
  {
    displayName: "Saint Barthelemy +590",
    value: "+590",
    code: "BL",
    text: "BL +590"
  },
  {
    displayName: "Saint Helena, Ascension and Tristan Da Cunha +290",
    value: "+290",
    code: "SH",
    text: "SH +290"
  },
  {
    displayName: "Saint Kitts and Nevis +1869",
    value: "+1869",
    code: "KN",
    text: "KN +1869"
  },
  {
    displayName: "Saint Lucia +1758",
    value: "+1758",
    code: "LC",
    text: "LC +1758"
  },
  {
    displayName: "Saint Martin +590",
    value: "+590",
    code: "MF",
    text: "MF +590"
  },
  {
    displayName: "Saint Pierre and Miquelon +508",
    value: "+508",
    code: "PM",
    text: "PM +508"
  },
  {
    displayName: "Saint Vincent and the Grenadines +1784",
    value: "+1784",
    code: "VC",
    text: "VC +1784"
  },
  {
    displayName: "Samoa +685",
    value: "+685",
    code: "WS",
    text: "WS +685"
  },
  {
    displayName: "San Marino +378",
    value: "+378",
    code: "SM",
    text: "SM +378"
  },
  {
    displayName: "Sao Tome and Principe +239",
    value: "+239",
    code: "ST",
    text: "ST +239"
  },
  {
    displayName: "Saudi Arabia +966",
    value: "+966",
    code: "SA",
    text: "SA +966"
  },
  {
    displayName: "Senegal +221",
    value: "+221",
    code: "SN",
    text: "SN +221"
  },
  {
    displayName: "Serbia +381",
    value: "+381",
    code: "RS",
    text: "RS +381"
  },
  {
    displayName: "Seychelles +248",
    value: "+248",
    code: "SC",
    text: "SC +248"
  },
  {
    displayName: "Sierra Leone +232",
    value: "+232",
    code: "SL",
    text: "SL +232"
  },
  {
    displayName: "Singapore +65",
    value: "+65",
    code: "SG",
    text: "SG +65"
  },
  {
    displayName: "Slovakia +421",
    value: "+421",
    code: "SK",
    text: "SK +421"
  },
  {
    displayName: "Slovenia +386",
    value: "+386",
    code: "SI",
    text: "SI +386"
  },
  {
    displayName: "Solomon Islands +677",
    value: "+677",
    code: "SB",
    text: "SB +677"
  },
  {
    displayName: "Somalia +252",
    value: "+252",
    code: "SO",
    text: "SO +252"
  },
  {
    displayName: "South Africa +27",
    value: "+27",
    code: "ZA",
    text: "ZA +27"
  },
  {
    displayName: "South Sudan +211",
    value: "+211",
    code: "SS",
    text: "SS +211"
  },
  {
    displayName: "South Georgia and the South Sandwich Islands +500",
    value: "+500",
    code: "GS",
    text: "GS +500"
  },
  {
    displayName: "Spain +34",
    value: "+34",
    code: "ES",
    text: "ES +34"
  },
  {
    displayName: "Sri Lanka +94",
    value: "+94",
    code: "LK",
    text: "LK +94"
  },
  {
    displayName: "Sudan +249",
    value: "+249",
    code: "SD",
    text: "SD +249"
  },
  {
    displayName: "Suriname +597",
    value: "+597",
    code: "SR",
    text: "SR +597"
  },
  {
    displayName: "Svalbard and Jan Mayen +47",
    value: "+47",
    code: "SJ",
    text: "SJ +47"
  },
  {
    displayName: "Swaziland +268",
    value: "+268",
    code: "SZ",
    text: "SZ +268"
  },
  {
    displayName: "Sweden +46",
    value: "+46",
    code: "SE",
    text: "SE +46"
  },
  {
    displayName: "Switzerland +41",
    value: "+41",
    code: "CH",
    text: "CH +41"
  },
  {
    displayName: "Syrian Arab Republic +963",
    value: "+963",
    code: "SY",
    text: "SY +963"
  },
  {
    displayName: "Taiwan +886",
    value: "+886",
    code: "TW",
    text: "TW +886"
  },
  {
    displayName: "Tajikistan +992",
    value: "+992",
    code: "TJ",
    text: "TJ +992"
  },
  {
    displayName: "Tanzania, United Republic of Tanzania +255",
    value: "+255",
    code: "TZ",
    text: "TZ +255"
  },
  {
    displayName: "Thailand +66",
    value: "+66",
    code: "TH",
    text: "TH +66"
  },
  {
    displayName: "Timor-Leste +670",
    value: "+670",
    code: "TL",
    text: "TL +670"
  },
  {
    displayName: "Togo +228",
    value: "+228",
    code: "TG",
    text: "TG +228"
  },
  {
    displayName: "Tokelau +690",
    value: "+690",
    code: "TK",
    text: "TK +690"
  },
  {
    displayName: "Tonga +676",
    value: "+676",
    code: "TO",
    text: "TO +676"
  },
  {
    displayName: "Trinidad and Tobago +1868",
    value: "+1868",
    code: "TT",
    text: "TT +1868"
  },
  {
    displayName: "Tunisia +216",
    value: "+216",
    code: "TN",
    text: "TN +216"
  },
  {
    displayName: "Turkey +90",
    value: "+90",
    code: "TR",
    text: "TR +90"
  },
  {
    displayName: "Turkmenistan +993",
    value: "+993",
    code: "TM",
    text: "TM +993"
  },
  {
    displayName: "Turks and Caicos Islands +1649",
    value: "+1649",
    code: "TC",
    text: "TC +1649"
  },
  {
    displayName: "Tuvalu +688",
    value: "+688",
    code: "TV",
    text: "TV +688"
  },
  {
    displayName: "Uganda +256",
    value: "+256",
    code: "UG",
    text: "UG +256"
  },
  {
    displayName: "Ukraine +380",
    value: "+380",
    code: "UA",
    text: "UA +380"
  },
  {
    displayName: "United Arab Emirates +971",
    value: "+971",
    code: "AE",
    text: "AE +971"
  },
  {
    displayName: "United Kingdom +44",
    value: "+44",
    code: "GB",
    text: "GB +44"
  },
  {
    displayName: "United States +1",
    value: "+1",
    code: "US",
    text: "US +1"
  },
  {
    displayName: "Uruguay +598",
    value: "+598",
    code: "UY",
    text: "UY +598"
  },
  {
    displayName: "Uzbekistan +998",
    value: "+998",
    code: "UZ",
    text: "UZ +998"
  },
  {
    displayName: "Vanuatu +678",
    value: "+678",
    code: "VU",
    text: "VU +678"
  },
  {
    displayName: "Venezuela, Bolivarian Republic of Venezuela +58",
    value: "+58",
    code: "VE",
    text: "VE +58"
  },
  {
    displayName: "Vietnam +84",
    value: "+84",
    code: "VN",
    text: "VN +84"
  },
  {
    displayName: "Virgin Islands, British +1284",
    value: "+1284",
    code: "VG",
    text: "VG +1284"
  },
  {
    displayName: "Virgin Islands, U.S. +1340",
    value: "+1340",
    code: "VI",
    text: "VI +1340"
  },
  {
    displayName: "Wallis and Futuna +681",
    value: "+681",
    code: "WF",
    text: "WF +681"
  },
  {
    displayName: "Yemen +967",
    value: "+967",
    code: "YE",
    text: "YE +967"
  },
  {
    displayName: "Zambia +260",
    value: "+260",
    code: "ZM",
    text: "ZM +260"
  },
  {
    displayName: "Zimbabwe +263",
    value: "+263",
    code: "ZW",
    text: "ZW +263"
  }
]
