const GlobalLoader = (props) => {
  return (
    <>
      <div className={`global-loader ${props.loaderBackground && 'bg-white'}`}>
        <div className="card-block-loader" style={{ ...props.style }}>
          <div className="blockUI"></div>
          <div className="sk-swing sk-primary">
            <div className="sk-swing-dot"></div>
            <div className="sk-swing-dot"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GlobalLoader
