import {
  showToastr,
  NeoComponents,
  FoneAlert
} from "@forcepoint/platform-utilityui"
import {
  coreStore,
  GET_PENDING_EULAS,
  SET_PENDING_EULAS,
  APP_URL,
  APP_LOGOUT
} from "@forcepoint/platform-coreui"
import { useState } from "react"
import { AlertProps } from "../../navigation.model"

const EulaAcceptanceModal = (props) => {
  /**
   * To store eula object
   */
  const eulaObj = props?.eulaObj ?? {}

  /**
   * To store scopes
   */
  const scopes = eulaObj?.userResponse?.scope

  /**
   * To store pending list
   */
  const pendingList = eulaObj?.pendingEulas ?? null

  /**
   * To store is terms accepted
   */
  const [isTermsAccepted, setTermsAccepted] = useState(false)

  /**
   * To store alert props
   */
  const [alertProps, setAlertProps] = useState<AlertProps>()

  /**
   * To show confirmation popup
   */
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

  /**
   * Submit event
   * @param item
   */
  const submit = (item) => {
    const url = APP_URL
    fetch(`${url}/api/eulas/accept`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${eulaObj.token}`
      },
      body: JSON.stringify({
        eulaId: item.ID
      })
    })
      .then((res) => {
        props.setGlobalLoader(true)
        props.setEulaObj(null)
        props.setPopupOpenStatus(false)
        if (res.status === 201) {
          const list = [...pendingList.filter((val) => val.ID !== item.ID)]
          if (list.length === 0) {
            coreStore.dispatch({
              type: GET_PENDING_EULAS,
              payload: { ...eulaObj, userToken: eulaObj.token }
            })
          } else if (list.length > 0) {
            coreStore.dispatch({
              type: SET_PENDING_EULAS,
              payload: { ...eulaObj, pendingEulas: list, token: eulaObj.token }
            })
          }
          return res.json()
        } else if (res.status === 409) {
          showToastr({
            type: "error",
            message: `EULA already accepted`
          })
          coreStore.dispatch({
            type: GET_PENDING_EULAS,
            payload: { ...eulaObj, userToken: eulaObj.token }
          })
          return Promise.resolve({})
        } else if (res.status === 403) {
          showToastr({
            type: "error",
            message: `Please contact administrator for accepting EULA`
          })
        } else {
          showToastr({
            type: "error",
            message: `Some erorr occurred. Unable to accept EULA`
          })
        }
        return Promise.reject(res)
      })
      .catch((err) => {
        const toastConfig = {
          type: "error",
          message: `Some error occurred. Unable to accept Eula`
        }
        showToastr(toastConfig)
      })
  }

  /**
   * Cancel event
   */
  const cancel = () => {
    coreStore.dispatch({ type: APP_LOGOUT })
    props.setPopupOpenStatus(false)
    window.location.href = `/platform/login?page=${(
      window.location.pathname + window.location.hash
    ).slice(1)}`
  }

  /**
   * On Click cancel button
   */
  const onClickCancelBtn = () => {
    setAlertProps({
      type: "warning",
      title: "Cancel Agreement",
      text: "Do you want to cancel the agreement? You will be redirected to login page if you select 'Confirm'",
      positiveBtnText: "Confirm",
      negativeBtnText: "Cancel",
      showNegativeBtn: true,
      positiveBtnCallback: () => {
        cancel()
      },
      negativeBtnCallback: () => {
        setShowConfirmationPopup(false)
      }
    })
    setShowConfirmationPopup(true)
  }

  /**
   * UI Template
   */
  return (
    <div
      className={
        "fone-modal fone-modal-lg " + (props.popupOpenStatus ? "show " : "")
      }
    >
      <div className="fone-modal-overlay"></div>
      <div className="fone-modal-content">
        {pendingList &&
          pendingList.length > 0 &&
          [pendingList?.[0]].map((item) => {
            return (
              <div key={item.ID}>
                {!props.hideHeader ? (
                  <div className="fone-modal-header">
                    <h2 className="fone-modal-title">
                      <img
                        style={{ height: "2rem" }}
                        src="/assets/images/f_logo_dark.svg"
                      />
                    </h2>
                  </div>
                ) : null}

                <div className="fone-modal-body eula-modal">
                  <div
                    style={{
                      display: "grid",
                      paddingBottom: "20px",
                      textAlign: "center"
                    }}
                  >
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0% !important"
                      }}
                    >
                      <b>FORCEPOINT</b>
                    </label>
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        margin: "0% !important"
                      }}
                    >
                      <b>NETWORK SECURITY PRODUCTS</b>
                    </label>
                    <label
                      style={{
                        color: "#414b55",
                        fontSize: "20px",
                        textAlign: "center",
                        margin: "0% !important"
                      }}
                    >
                      <b>LICENSE AGREEMENT</b>
                    </label>
                  </div>
                  <div className="eula-acceptance">
                    <p
                      className="eula-html-para"
                      dangerouslySetInnerHTML={{ __html: item.HTMLContent }}
                    ></p>
                  </div>
                </div>

                <div className="eula-footer">
                  <div className="eula-footer-checkbox">
                    <NeoComponents.NeoCheckbox
                      id={"terms"}
                      onChange={(val) => {
                        setTermsAccepted(val?.target?.checked)
                      }}
                      checked={isTermsAccepted}
                      disabled={
                        !scopes?.includes("eula.accept") &&
                        !scopes?.includes("eula.search")
                      }
                    ></NeoComponents.NeoCheckbox>{" "}
                    I accept the term in this agreement
                  </div>
                  <div style={{ display: "flex" }}>
                    <NeoComponents.NeoSecondaryButton
                      onClick={onClickCancelBtn}
                    >
                      Cancel
                    </NeoComponents.NeoSecondaryButton>
                    <span style={{ marginRight: "1rem" }}></span>
                    <NeoComponents.NeoPrimaryButton
                      isDisabled={
                        !isTermsAccepted ||
                        (!scopes?.includes("eula.accept") &&
                          !scopes?.includes("eula.search"))
                      }
                      onClick={() => {
                        submit(item)
                      }}
                    >
                      Confirm
                    </NeoComponents.NeoPrimaryButton>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      {showConfirmationPopup ? <FoneAlert alertData={alertProps} /> : null}
    </div>
  )
}
export default EulaAcceptanceModal
