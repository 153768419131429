import { useEffect, useState } from "react"
import * as singleSpa from "single-spa"
import { coreStore, SET_PAGE_TITLE } from "@forcepoint/platform-coreui"
import { NeoComponents, isEmpty } from "@forcepoint/platform-utilityui"

export type MenuItem = {
  name: string
  customIconUrl?: string
  customIconStyle?: unknown
  localizationKey?: string
  pageTitle?: string
  icon?: string
  onClick: (menu: MenuItem) => void
  menus?: MenuItem[]
  children?: MenuItem[]
}

export default function SideMenu({ menus, name, activeApp, activeMenu }) {
  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component States --------------------------------------
  // ----------------------------------------------------------------------------------------
  const activeApplicationCode = coreStore.getState()?.activeApp?.applicationCode
  const tenantIdList = coreStore.getState()?.userInfo?.ext?.tenantId
  const applicationsList = coreStore.getState()?.applications

  /**
   * To store menu items
   */
  const [menuItems, setMenuItems] = useState([])

  /**
   * To set active item
   */
  const [activeItem, setActiveItem] = useState()

  const [showSideMenu, setShowSideMenu] = useState(true)

  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component Effets --------------------------------------
  // ----------------------------------------------------------------------------------------
  const prepareMenuList = (menus: MenuItem[]) => {
    const list = []
    for (const menu of menus) {
      const menuItem: MenuItem = {
        name: menu.name,
        customIconUrl: menu.customIconUrl,
        customIconStyle: menu.customIconStyle,
        localizationKey: menu.localizationKey,
        pageTitle: menu.pageTitle,
        icon: menu.icon,
        onClick: () => navigateToUrl(menu)
      }
      if (menu.menus && menu.menus.length > 0) {
        menuItem.children = prepareMenuList(menu.menus)
      }
      list.push(menuItem)
    }
    return list
  }
  /**
   * To set menus
   */
  useEffect(() => {
    const menuItems = prepareMenuList(menus)
    setMenuItems(menuItems)
  }, [menus])

  /**
   * To set active item
   */
  useEffect(() => {
    setActiveItem(activeMenu?.name)
  }, [activeMenu])

  useEffect(() => {
    const application = applicationsList?.find(
      (x) => x?.applicationCode === activeApplicationCode
    )

    const tenantId = tenantIdList.find(
      (x) => x?.hint === activeApplicationCode
    )?.id

    if (
      !isEmpty(application?.webhooks?.tenantOnboarding) &&
      isEmpty(tenantId)
    ) {
      setShowSideMenu(false)
    } else {
      setShowSideMenu(true)
    }
  }, [activeApplicationCode])

  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component Methods -------------------------------------
  // ----------------------------------------------------------------------------------------

  /**
   * Navigate to url
   * @param url
   * @param name
   */
  const navigateToUrl = (menu) => {
    coreStore.dispatch({ type: SET_PAGE_TITLE, pageTitle: menu.pageTitle })
    singleSpa.navigateToUrl(menu.url)
  }

  // ----------------------------------------------------------------------------------------
  // ----------------------------------- HTML Templates -------------------------------------
  // ----------------------------------------------------------------------------------------

  return (
    <>
      {showSideMenu && (
        <div className="app-sidemenu-container">
          <NeoComponents.NeoSideMenu
            menuItems={menuItems}
            helpIconUrl={activeApp?.supportUrls?.help}
            activeItem={activeItem}
            hideMenuHeader
            onMenuHeaderClick={function (): void {
              throw new Error("Function not implemented")
            }}
          />
        </div>
      )}
    </>
  )
}
