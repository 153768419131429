import {
  Container,
  NeoComponents,
  CardLoader,
  StyleComponents
} from "@forcepoint/platform-utilityui"
import { useAppDispatch } from "../../../common/state/app.state"
import { useEffect, useState } from "react"
import userProfileService from "../service/userprofile.service"
import QRCode from "qrcode"
import {
  getActionStatus,
  getDuoUserConfig,
  getDuoStatus
} from "../state/userprofile.selector"
import { ActionState } from "../state/userprofile.state"
import OTPInputBox from "./OTPInputBox"
import { coreStore } from "@forcepoint/platform-coreui"
import { isValidOTPDigit } from "../userprofile.model"
const initPasscodeVal = ["", "", "", "", "", ""]
const DuoAuthenticator = ({ userId }) => {
  const dispatchApi = useAppDispatch()
  const enableResetBtn = true
  const duoStatus = getDuoStatus(userId)
  const duoConfig = getDuoUserConfig()
  const actionStatus = getActionStatus()
  const [duoQRUrl, setDuoQRUrl] = useState("")

  const [showDuoSetup, setShowDuoSetup] = useState(false)
  const [duoPasscode, setDuoPasscode] = useState([...initPasscodeVal])

  useEffect(() => {
    dispatchApi(userProfileService.getDuoStatus({ id: userId }))
    return () => {
      dispatchApi(userProfileService.resetDuoStatus(userId))
    }
  }, [])

  useEffect(() => {
    if (duoConfig.value && duoConfig.value.activationBarcode) {
      setShowDuoSetup(true)
      generateQRcode(duoConfig.value.activationBarcode)
    }
  }, [duoConfig])

  useEffect(() => {
    if (
      enableResetBtn &&
      actionStatus[ActionState.RESET_DUO_USER_CONFIG] &&
      actionStatus[ActionState.RESET_DUO_USER_CONFIG].actionStatus === "success"
    ) {
      setShowDuoSetup(false)
      dispatchApi(userProfileService.getDuoStatus({ id: userId }))
      dispatchApi(
        userProfileService.resetActionStatus(ActionState.RESET_DUO_USER_CONFIG)
      )
    }
    if (
      actionStatus[ActionState.VERIFY_DUO_USER] &&
      actionStatus[ActionState.VERIFY_DUO_USER].actionStatus === "success"
    ) {
      if (actionStatus[ActionState.VERIFY_DUO_USER]?.value?.status) {
        dispatchApi(userProfileService.getDuoStatus({ id: userId }))
      } else {
        setDuoPasscode([...initPasscodeVal])
      }
      dispatchApi(
        userProfileService.resetActionStatus(ActionState.VERIFY_DUO_USER)
      )
    }
  }, [actionStatus])

  const duoSetup = () => {
    dispatchApi(userProfileService.getDuoUserConfig())
  }
  const verifyDuoUser = () => {
    if (duoPasscode.every(isValidOTPDigit)) {
      dispatchApi(
        userProfileService.verifyDuoUser({
          passcode: duoPasscode.join("")
        })
      )
    }
  }

  const generateQRcode = (str) => {
    QRCode.toDataURL(str, function (err, url) {
      setDuoQRUrl(url)
    })
  }

  const resetduoConfig = () => {
    if (enableResetBtn) {
      dispatchApi(userProfileService.resetDuoUserConfig({ id: userId }))
    }
  }
  return (
    <Container>
      <NeoComponents.NeoExpandableContainer>
        <NeoComponents.NeoCenterFlex
          style={{ width: "100%", height: "100%", flexGrow: 1 }}
        >
          <form className="common-form" noValidate={true}>
            <StyleComponents.Title className="duo-title">
              Duo User Setup
            </StyleComponents.Title>
            <div style={{ width: "100%" }}>
              {duoStatus.actionStatus === "pending" ? (
                <div>
                  <CardLoader />
                </div>
              ) : null}
              {duoStatus.value && duoStatus.actionStatus === "success" ? (
                <div className="duo-configured-container">
                  <div className="duo-configured">
                    <NeoComponents.NeoIcon
                      fill="green"
                      hoverFill="green"
                      name="success_circle"
                      onClick={function noRefCheck() {}}
                      size={12}
                    />
                    <span className="duo-configured-text">
                      Duo User configured
                    </span>
                  </div>
                  {enableResetBtn ? (
                    <NeoComponents.NeoPrimaryButton
                      onClick={resetduoConfig}
                      isLoading={
                        actionStatus[ActionState.RESET_DUO_USER_CONFIG] &&
                        actionStatus[ActionState.RESET_DUO_USER_CONFIG]
                          .actionStatus === "pending"
                      }
                    >
                      Reset
                    </NeoComponents.NeoPrimaryButton>
                  ) : null}
                </div>
              ) : null}
              {showDuoSetup &&
              !duoStatus.value &&
              duoStatus.actionStatus === "success" &&
              duoConfig.value.activationBarcode &&
              duoConfig.actionStatus === "success" ? (
                <>
                  <div className="qr-text-center">
                    <p className="scan-text">
                      Scan the QR code using the Duo Authenticator App on your
                      Android or iOS device.
                    </p>
                    <img
                      height={"170px"}
                      width={"auto"}
                      src={duoQRUrl}
                      alt="QR Code"
                    />
                  </div>
                  {duoConfig.value.activationBarcode ? (
                    <div className="inputs otp-container">
                      <OTPInputBox
                        passcode={duoPasscode}
                        setPassCode={setDuoPasscode}
                      />
                      <label htmlFor="otp-box-0">Verification code</label>
                    </div>
                  ) : null}
                </>
              ) : null}
              {!showDuoSetup &&
              !duoStatus.value &&
              duoStatus.actionStatus === "success" ? (
                <div className="start-duo-btn">
                  <NeoComponents.NeoPrimaryButton
                    isLoading={duoConfig.actionStatus === "pending"}
                    onClick={duoSetup}
                  >
                    Start Duo User Setup
                  </NeoComponents.NeoPrimaryButton>
                </div>
              ) : null}
            </div>

            {showDuoSetup &&
            !duoStatus.value &&
            duoStatus.actionStatus === "success" ? (
              <div className="verify-duo-btn">
                <NeoComponents.NeoPrimaryButton
                  isDisabled={!duoPasscode.every(isValidOTPDigit)}
                  isLoading={
                    duoConfig.actionStatus === "pending" ||
                    (actionStatus[ActionState.VERIFY_DUO_USER] &&
                      actionStatus[ActionState.VERIFY_DUO_USER].actionStatus ===
                        "pending")
                  }
                  onClick={verifyDuoUser}
                >
                  Verify
                </NeoComponents.NeoPrimaryButton>
              </div>
            ) : null}
          </form>
        </NeoComponents.NeoCenterFlex>
      </NeoComponents.NeoExpandableContainer>
    </Container>
  )
}
export default DuoAuthenticator
