import { NeoComponents } from "@forcepoint/platform-utilityui"
import {
  coreStore,
  APP_LOGOUT,
  clearStorageData
} from "@forcepoint/platform-coreui"
import { useEffect, useState } from "react"
import { setLocalPersistSession } from "../sessionMgmt"
import * as singleSpa from "single-spa"

export const SessionAlert = (props) => {
  /**
   * To store app messages
   */
  const appMessages = coreStore.getState()?.applicationMessages
  /**
   * To store and set session persistance state
   */
  const [persistSession, setPersistSession] = useState(false)
  const timer = props.sessionLogoutTimer * 60
  const [sessionLogoutTimer, setSessionLogoutTimer] = useState(timer)
  useEffect(() => {
    const interval = setInterval(() => {
      setSessionLogoutTimer((val) => {
        if (val <= 0) {
          clearStorageData(false)
          clearInterval(interval)
          return val
        }
        return val - 1
      })
    }, 1000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [sessionLogoutTimer])

  const getDisplayTimer = (time: number): string => {
    const minutes = parseInt(time / 60 + "")
    const seconds = time % 60
    return time > 0
      ? ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2)
      : ""
  }

  const onContinueSessionClick = () => {
    if (persistSession) {
      setLocalPersistSession(persistSession)
    }
    props.setShowIdleTimeoutAlert(false)
  }

  return (
    <div className={`fone-alert show-fone-alert`}>
      <div className={`fone-alert-content-medium`}>
        <div className={`fone-alert-icon fone-alert-warning`}>
          <span> </span>
        </div>
        <div className="fone-alert-body">
          <h3 className="fone-alert-title" style={{ marginBottom: "10px" }}>
            Session Timeout
          </h3>
          <span style={{ color: "#97aaba", fontSize: "24px" }}>
            {getDisplayTimer(sessionLogoutTimer)}
          </span>
          <p
            className="fone-alert-para"
            style={{
              fontSize: "14px",
              marginTop: "10px",
              marginBottom: "10px"
            }}
          >
            {sessionLogoutTimer > 0
              ? appMessages?.messages?.header?.USER_IDLE_MSG
              : appMessages?.messages?.header?.USER_SESSION_EXPIRED}
          </p>
        </div>
        {sessionLogoutTimer > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              marginBottom: "16px"
            }}
          >
            <NeoComponents.NeoCheckbox
              id={"persistSession"}
              checked={persistSession}
              onChange={(val) => {
                setPersistSession(val?.target?.checked)
                props.setPersistSession(val?.target?.checked)
              }}
            ></NeoComponents.NeoCheckbox>{" "}
            <span style={{ fontSize: "12px", color: "#5e778d" }}>
              Do not show me this message again
            </span>
            <div className="fone-alert-footer"></div>
          </div>
        ) : null}
        <div className="fone-alert-footer">
          {sessionLogoutTimer > 0 ? (
            <>
              <NeoComponents.NeoSecondaryButton
                onClick={() => {
                  setPersistSession(false)
                  coreStore.dispatch({ type: APP_LOGOUT })
                  singleSpa.navigateToUrl("/logout")
                  const closeTab = window.open("", "_self")
                  closeTab.close()
                  props.setShowIdleTimeoutAlert(false)
                }}
              >
                Logout
              </NeoComponents.NeoSecondaryButton>
              <NeoComponents.NeoPrimaryButton onClick={onContinueSessionClick}>
                Continue
              </NeoComponents.NeoPrimaryButton>
            </>
          ) : (
            <NeoComponents.NeoSecondaryButton
              onClick={() => {
                clearStorageData(false)
                window.location.href = `/platform/login?page=${(
                  window.location.pathname + window.location.hash
                ).slice(1)}`
              }}
            >
              Login Again
            </NeoComponents.NeoSecondaryButton>
          )}
        </div>
      </div>
    </div>
  )
}
