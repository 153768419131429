import { createSelector } from "@reduxjs/toolkit"
import { RootState, useAppSelector } from "../../../common/state/app.state"

/**
 * Selector to get User By Id
 * @returns
 */
export const getUserById = () => {
  return useAppSelector((state: RootState) => {
    return state.userProfile.userById
  })
}

const defaultTotpStatus = { value: false, actionStatus: null }
const defaultDuoStatus = { value: false, actionStatus: null }
/**
 * Selector to get totp status
 * @returns
 */
export const getTotpStatus = (id) => {
  const selectStatusById: any = createSelector(
    [(state) => state.userProfile.totpStatus],
    (status) => {
      return status[id] ? status[id] : defaultTotpStatus
    }
  )
  return useAppSelector((state: RootState): any => {
    return selectStatusById(state, id)
  })
}
/**
 * Selector to get duo Configuration
 * @returns
 */
export const getDuoConfiguration = () => {
  return useAppSelector((state: RootState) => {
    return state.userProfile.duoConfiguration
  })
}

export const getTotpConfig = () => {
  return useAppSelector((state: RootState) => {
    return state.userProfile.totpConfig
  })
}
export const getDuoUserConfig = () => {
  return useAppSelector((state: RootState) => {
    return state.userProfile.duoUserConfig
  })
}
/**
 * Selector to get duo status
 * createSelector method is used to memoize selector value and avoid rerendering
 */
export const getDuoStatus = (id) => {
  const selectStatusById: any = createSelector(
    [(state) => state.userProfile.duoStatus],
    (status) => {
      return status[id] ? status[id] : defaultDuoStatus
    }
  )
  return useAppSelector((state: RootState): any => {
    return selectStatusById(state, id)
  })
}

/**
 * Selector to get action status
 * @returns
 */
export const getActionStatus = () => {
  return useAppSelector((state: RootState) => {
    return state.userProfile.actionStatus
  })
}
