export const sessionTabKey = "tabCount"

/* sessionLogoutTime: in Minutes */
export const sessionIdleTime = 25
export const sessionLogoutTimer = 5

const getCount = (str: string) => {
  return str && !isNaN(+str) ? +str : 0
}

export const getTabCount = () => {
  const str = localStorage.getItem(sessionTabKey)
  return getCount(str)
}
export const isTabActive = () => {
  const str = localStorage.getItem("isFocused")
  return str === "true"
}

export const setLocalPersistSession = (persistSession: boolean): void => {
  localStorage.setItem("persistSession", JSON.stringify(persistSession))
}
export const getLocalPersistSession = () => {
  const str = localStorage.getItem("persistSession")
  return str === "true"
}

// Check if the browser supports the Page Visibility API
if (typeof document.hidden !== "undefined") {
  document.addEventListener("visibilitychange", function () {
    if (document.hidden) {
      localStorage.setItem("isFocused", "false")
    } else {
      localStorage.setItem("isFocused", "true")
    }
  })
}
