export const APICONSTANTS = {
  USERS: {
    GET_USER_BY_ID: {
      LOCAL: "/user.json",
      API_SERVER: "api/users/{id}",
      LOCAL_REQUEST_TYPE: "GET",
      API_SERVER_REQUEST_TYPE: "GET"
    },
    EDIT_USER: {
      LOCAL: "/user.json",
      API_SERVER: "api/users/{id}",
      LOCAL_REQUEST_TYPE: "GET",
      API_SERVER_REQUEST_TYPE: "PUT"
    }
  },

  USERPROFILE: {
    GET_DUO_CONFIGURATION: {
      LOCAL: "/getDuoSettings.json",
      API_SERVER: "api/tenants/current/duo-settings",
      LOCAL_REQUEST_TYPE: "GET",
      API_SERVER_REQUEST_TYPE: "GET"
    },
    GET_DUO_STATUS: {
      LOCAL: "/getTotpStatus.json",
      API_SERVER: "api/duo/user/{id}/status",
      LOCAL_REQUEST_TYPE: "GET",
      API_SERVER_REQUEST_TYPE: "GET"
    },
    GET_DUO_USER_CONFIG: {
      LOCAL: "/getDuoConfig.json",
      API_SERVER: "api/duo/user",
      LOCAL_REQUEST_TYPE: "POST",
      API_SERVER_REQUEST_TYPE: "POST"
    },
    RESET_DUO_USER_CONFIG: {
      LOCAL: "/resetduoConfig.json",
      API_SERVER: "api/duo/user/{id}/reset",
      LOCAL_REQUEST_TYPE: "PUT",
      API_SERVER_REQUEST_TYPE: "PUT"
    },
    VERIFY_DUO_USER: {
      LOCAL: "verifyduo.json",
      API_SERVER: "api/duo/verify",
      LOCAL_REQUEST_TYPE: "POST",
      API_SERVER_REQUEST_TYPE: "POST"
    },
    GET_TOTP_STATUS: {
      LOCAL: "/getTotpStatus.json",
      API_SERVER: "api/totp/user/{id}/status",
      LOCAL_REQUEST_TYPE: "GET",
      API_SERVER_REQUEST_TYPE: "GET"
    },
    GET_TOTP_CONFIG: {
      LOCAL: "/getTotpConfig.json",
      API_SERVER: "api/totp/user",
      LOCAL_REQUEST_TYPE: "POST",
      API_SERVER_REQUEST_TYPE: "POST"
    },
    RESET_TOTP_CONFIG: {
      LOCAL: "/resetTotpConfig.json",
      API_SERVER: "api/totp/user/{id}/reset",
      LOCAL_REQUEST_TYPE: "PUT",
      API_SERVER_REQUEST_TYPE: "PUT"
    },
    VERIFY_TOTP: {
      LOCAL: "verifyTotp.json",
      API_SERVER: "api/totp/verify",
      LOCAL_REQUEST_TYPE: "POST",
      API_SERVER_REQUEST_TYPE: "POST"
    }
  }
}
