import Header from "./components/Header/Header"
import SideMenu from "./components/Sidebar/Sidebar"
import { useState, useEffect } from "react"
import { Provider } from "react-redux"
import {
  coreStore,
  runTimeoutForRefreshToken,
  SET_ID_TOKEN,
  SET_USER_TOKEN
} from "@forcepoint/platform-coreui"
import GlobalLoader from "./components/GlobalLoader/GlobalLoader"
import SettingsSidebar from "./components/SettingsSidebar/SettingsSidebar"
import {
  NeoErrorBoundary,
  NeoThemeProvider,
  Intl,
  NeoComponents,
  Toastr,
  messages
} from "@forcepoint/platform-utilityui"
import EulaAcceptanceModal from "./components/Eula/EulaAcceptanceModal"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import UserProfileContainer from "./components/UserProfile/UserProfileContainer"
import store from "./common/state/app.state"
import { FoneAlert } from "@forcepoint/platform-utilityui"

let navigationSubscription = null
/**
 * Navigation UI root.
 * @param props
 * @returns
 */
export default function Root(props) {
  // ----------------------------------------------------------------------------------------
  // ---------------------------------- Component States ------------------------------------
  // ----------------------------------------------------------------------------------------
  const path = window?.location?.pathname?.includes("editprofile")

  /**
   * To store state data
   */
  const stateData = coreStore.getState()

  /**
   * To set all menus
   */
  const [appMenus, setMenus] = useState([])

  /**
   * To set active menu
   */
  const [activeMenu, setActiveMenu] = useState()

  /**
   * To show sidebar
   */
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  /**
   * To set app title
   */
  const [appTitle, setAppTitle] = useState<string>(stateData.appTitle)

  /**
   * To set applications
   */
  const [applications, setApplications] = useState(stateData.applications)

  /**
   * To set active app
   */
  const [activeApp, setActiveApp] = useState(stateData.activeApp)

  /**
   * To set user info
   */
  const [userInfo, setUserInfo] = useState()

  /**
   * To set global loader
   */
  const [globalLoader, setGlobalLoader] = useState<boolean>(
    stateData.showGlobalLoader
  )

  /**
   * To set page title
   */
  const [pageTitle, setPageTitle] = useState<string>(stateData.pageTitle)

  /**
   * To set settings sidebar status
   */
  const [settingsSidebar, setSettingsSidebar] = useState<boolean>(
    stateData.isSettingsPage
  )

  /**
   * To show sidebar
   */
  const [showEulaAcceptance, setShowEulaAcceptance] = useState(false)

  /**
   * To store eula details
   */
  const [eulaObj, setEulaObj] = useState(null)

  /**
   * To show white loader
   */
  const [showWhiteLoader, setShowWhiteLoader] = useState<boolean>(
    stateData.showWhiteLoader
  )

  /**
   * To store fone alert config
   */
  const [foneAlertConfig, setFoneAlertConfig] = useState({
    status: false,
    alertProps: null
  })

  /**
   * To store event method
   */
  const eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent"

  /**
   * To store window event
   */
  const windowEvent = window[eventMethod]

  /**
   * To store message event
   */
  const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message"

  // ----------------------------------------------------------------------------------------
  // --------------------------------- Component Effects ------------------------------------
  // ----------------------------------------------------------------------------------------

  if (!navigationSubscription) {
    navigationSubscription = coreStore.subscribe(() => {
      const storeData = coreStore.getState() // Getting state data
      setShowWhiteLoader(storeData.showWhiteLoader)
      let eulaConfig = null
      if (
        storeData.eulaConfig?.pendingEulas &&
        storeData.eulaConfig.pendingEulas.length > 0
      ) {
        eulaConfig = storeData.eulaConfig
        setGlobalLoader(false)
        setShowEulaAcceptance(true)
      } else {
        setGlobalLoader(storeData.showGlobalLoader) // Updating global loader show status
      }
      setEulaObj(eulaConfig)
      setUserInfo(storeData.userInfo) // Storing user info
      setFoneAlertConfig(storeData.foneAlertConfig)
      if (storeData.activeApp) {
        setActiveApp(storeData.activeApp) // Setting active app
        if (storeData.activeApp?.modules?.uiConfig?.enableSidebar) {
          if (!storeData.isSettingsPage) {
            document.querySelector("body").classList.add("with-sidebar") // Adding with sidebar class to body
            setShowSidebar(true) // Setting show sidebar status to true
            const menus = [...(storeData.activeApp?.menus || [])]
            setMenus(menus) // Storing menus
          }
        } else {
          document.querySelector("body").classList.remove("with-sidebar") // Removing with-sidebar class from body
          setShowSidebar(false) // Setting show sidebar status to false
        }
        setAppTitle(storeData.appTitle) // Setting app title
        setPageTitle(storeData.pageTitle) // Setting page title
        setActiveMenu(storeData.activeMenu) // Setting active menu
        setSettingsSidebar(storeData.isSettingsPage)
      } else {
        setMenus([])
        setActiveApp(null)
        setPageTitle(storeData.pageTitle)
        setAppTitle(null) // Setting app title
        setActiveMenu(null) // Setting active menu
        setShowSidebar(false)
        setSettingsSidebar(false)
        document.querySelector("body").classList.remove("with-sidebar")
      }
      if (storeData.applications.length > 0) {
        setApplications(storeData.applications) // Setting applications
      }
      if (storeData.mountedApps.length > 0) {
        for (const mountedApp of storeData.mountedApps) {
          if (mountedApp.location) {
            const element: HTMLElement = document.getElementById(
              `single-spa-application:${mountedApp.SPAName}`
            )
            element?.classList.add("SPA-Pos-" + mountedApp?.location)
            if (mountedApp.location === "header") {
              const shellMenu: HTMLElement =
                document.querySelector(".shell-menu")
              if (element) {
                element.style.right = `${shellMenu?.offsetWidth + 44}px`
              }
            }
          }
        }
      }
    })
  }
  /**
   * Core store subscriber call
   */
  useEffect(() => {
    windowEvent(
      messageEvent,
      (event) => {
        if (event.origin !== window.location.origin) return false
        if (event.data.message === "NEW_TOKEN_RECEIVED") {
          document.querySelector(".authIframe")?.remove()
          sessionStorage.removeItem("cookieCheck")
          coreStore.dispatch({
            type: SET_USER_TOKEN,
            userToken: event.data.decodedUserToken
          })
          coreStore.dispatch({
            type: SET_ID_TOKEN,
            idToken: event.data.decodedIdToken
          })
          runTimeoutForRefreshToken(event.data.dateInterval)
        }
      },
      false
    )
  }, [])

  /**
   * On Destory hook
   */
  useEffect(
    () => () => {
      if (navigationSubscription) {
        navigationSubscription()
        navigationSubscription = null
      }
    },
    []
  )

  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component Template ------------------------------------
  // ----------------------------------------------------------------------------------------

  return (
    <Provider store={store}>
      <NeoErrorBoundary>
        <NeoThemeProvider>
          <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
            <NeoComponents.NeoTooltips />
            <Toastr />
            <Header
              name={appTitle}
              applications={applications}
              activeApp={activeApp}
              userInfo={userInfo}
              pageTitle={pageTitle}
              settingsSidebar={settingsSidebar}
            />
            {showSidebar && !settingsSidebar ? (
              <SideMenu
                menus={appMenus}
                name={props.name}
                activeApp={activeApp}
                activeMenu={activeMenu}
              />
            ) : null}
            {!path && globalLoader ? (
              <GlobalLoader style={props.style} />
            ) : null}
            {showWhiteLoader ? (
              <GlobalLoader style={props.style} loaderBackground="#fff" />
            ) : null}
            {settingsSidebar ? <SettingsSidebar /> : null}
            {showEulaAcceptance && eulaObj ? (
              <EulaAcceptanceModal
                setEulaObj={setEulaObj}
                setGlobalLoader={setGlobalLoader}
                eulaObj={eulaObj}
                popupOpenStatus={showEulaAcceptance}
                setPopupOpenStatus={setShowEulaAcceptance}
              />
            ) : null}
            {foneAlertConfig.status && (
              <FoneAlert alertData={foneAlertConfig.alertProps} />
            )}
            <BrowserRouter basename="/">
              <Routes>
                <Route path={`/editprofile`} element={<UserProfileContainer />}>
                  <Route
                    path="*"
                    element={<Navigate to={`/editprofile`} replace />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </Intl.IntlProvider>
        </NeoThemeProvider>
      </NeoErrorBoundary>
    </Provider>
  )
}
