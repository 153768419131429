import { useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  useNeoWindowSize,
  StyleComponents,
  FoneAlert
} from "@forcepoint/platform-utilityui"
import { coreStore } from "@forcepoint/platform-coreui"
import EditUserProfile from "./components/EditUserProfile"
import UserProfileDrawer from "./UserProfileDrawer"
import { AlertProps } from "./userprofile.model"

/**
 * Users Container component
 * @param props
 * @returns
 */
const UserProfileContainer = (props) => {
  /**
   * To store window size
   */
  const windowSize = useNeoWindowSize()

  /**
   * To store location
   */
  const location = useLocation().pathname.split("/")

  /**
   * To store path
   */
  let path
  if (location.includes("configureMFA")) {
    path = "configureMFA"
  }

  /**
   * To store and set alert props
   */
  const [alertProps, setAlertProps] = useState<AlertProps>()

  /**
   * To set and show confirmation popup status
   */
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

  /**
   * To store route prefix
   */
  const routePrefix = coreStore.getState()?.activeApp?.modules?.moduleURLPrefix

  /**
   * To store is open status
   */
  const isOpen = useMemo(() => path, [path])

  /**
   * To store user drawer width
   */
  const userDrawerWidth = useMemo(
    () => (windowSize.width < 1500 ? "750px" : "45%"),
    [windowSize]
  )

  /**
   * UI Template
   */
  return (
    <StyleComponents.PageContainer>
      <StyleComponents.MainPage isOpen={isOpen} width={userDrawerWidth}>
        <StyleComponents.Page>
          <EditUserProfile isOpen={isOpen}/>
        </StyleComponents.Page>
      </StyleComponents.MainPage>
      {isOpen ? (
        <UserProfileDrawer
          isOpen={isOpen}
          userDrawerWidth={userDrawerWidth}
          path={path}
        />
      ) : null}
      {showConfirmationPopup ? <FoneAlert alertData={alertProps} /> : null}
    </StyleComponents.PageContainer>
  )
}

export default UserProfileContainer
