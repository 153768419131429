import { UserProfileState } from "../userprofile.model"

export enum ActionState {
  GET_USER_BY_ID = "[user profile app] Get User By id",
  GET_DUO_STATUS = "[user profile app] Get DUO Status",
  GET_DUO_CONFIGURATION = "[user profile app] Get Duo Configuration",
  GET_DUO_USER_CONFIG = "[user profile app] Get DUO user Config",
  RESET_DUO_USER_CONFIG = "[user profile app] Reset Duo User Config",
  VERIFY_DUO_USER = "[user profile app] Verify Duo user",
  GET_TOTP_STATUS = "[user profile app] Get TOTP Status",
  GET_TOTP_CONFIG = "[user profile app] Get TOTP Config",
  RESET_TOTP_CONFIG = "[user profile app] Reset TOTP Config",
  VERIFY_TOTP = "[user profile app] Verify TOTP",
  EDIT_USER = "[user profile app] edit User",
  RESET_USER_STATE = "[user profile app] reset user state",
  RESET_MFA_PAGE_STATE = "[user profile app] reset MFA page state",
  RESET_ACTION_STATUS = "[user profile app] Reset Action Status",
  RESET_TOTP_STATUS = "[user profile app] Reset TOTP Status",
  RESET_DUO_STATUS = "[user profile app] Reset DUO Status"
}

export const intialUserProfileState: UserProfileState = {
  userById: { value: null, actionStatus: null, showLoader: false },
  duoStatus: {},
  duoConfiguration: { value: null, actionStatus: null },
  duoUserConfig: { value: null, actionStatus: null },
  totpStatus: {},
  totpConfig: { value: {}, actionStatus: null },
  actionStatus: {}
}
